import logo from "./logo.jpg"

const  clientData = {
    client_entity: 80,
    attorney_firm: 'Daly & Kirk, PLLC',
    attorney_name: 'Vance Daly',
    attorney_number: '662-298-1925',
    attorney_email: 'kowensby@dalykirk.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_daly_kirk+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#0D4671',
    siteLink: 'https://www.dalykirk.com/',
    logo
}

export default clientData